import store from '@/store';
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import { getConfigEnv, sleep } from '@/utils/helpers';
import postNewTasq from '@/graphql/predictions/mutations/postNewTasq.graphql';
import updateStatus from '@/graphql/predictions/mutations/updateStatus.graphql';
import updateWellStatus from '@/graphql/predictions/mutations/updateWellStatus.graphql';
import unsnoozeJob from '@/graphql/predictions/mutations/unsnoozeJob.graphql';
import reassignTasq from '@/graphql/workflow/mutations/reassignTasq.graphql';
import deleteTasq from '@/graphql/workflow/mutations/deleteTasq.graphql';
import predictionsApolloClient from '@/lib/appsync/workflow';
import userResponsesApolloClient from '@/lib/appsync/workflow';
import postJobComment from '@/graphql/workflow/mutations/postJobComment.graphql';
import workflowApolloClient from '@/lib/appsync/workflow';
import updateWorkTicketTasq from '@/graphql/workflow/mutations/updateWorkTicketTasq.graphql';
import putDailyWorkTicketData from '@/graphql/workflow/mutations/putDailyWorkTicketData.graphql';
// import postGlobalWellComment from '@/graphql/workflow/mutations/postGlobalWellComment.graphql';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import updateUserResponse from '@/graphql/userResponses/mutations/updateUserResponse';
// import getProceduresStepsJson from '@/graphql/procedures/queries/getProceduresStepsJson.graphql';
import accountModule from '@/store/modules/accountModule';
import metaDataModule from '@/store/modules/metaDataModule';
// import proceduresApolloClient from '@/lib/appsync/procedures';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqActionsModule',
  store,
})
class TasqActionsModule extends VuexModule {
  @Action
  async addTasq(data: any = {}) {
    const {
      assetType,
      person,
      well,
      jobType,
      frequency,
      delayedDays,
      comment,
      stateChangeDate,
      stateChangeDateEnd,
      actions,
    } = data;
    try {
      const {
        data: {
          post_new_tasq: { id: predictionID, workflow_task_id: workflowTaskID },
        },
      } : any = await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            level: assetType,
            assigned_to_user: typeof person === 'object' ? person.value : person,
            created_by: accountModule.user.email,
            node_id: well,
            prediction_type: typeof jobType === 'object' ? jobType.value : jobType,
            ...(stateChangeDate && {
              state_change_date: new Date(stateChangeDate).toISOString().slice(0, -5),
            }),
            ...(stateChangeDateEnd && {
              state_change_date_end: new Date(stateChangeDateEnd).toISOString().slice(0, -5),
            }),
            ...(frequency === 'Reoccurance' && {
              recurring_start_date: new Date().toISOString(),
              recurring_end_date: new Date(new Date().getTime() + 86400000 * delayedDays).toISOString(),
            }),
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            comment: comment,
            // created_by: accountModule.user.email.toLowerCase()
          },
        },
      });
      if (jobType) {
        let jobTypeComment =
          (typeof jobType === 'object') ? `Job Type: ${jobType.key} - ${jobType.value}` : `Job Type: ${jobType}`;
        if (comment) {
          jobTypeComment = `${jobTypeComment} - Remarks: ${comment}`;
        }

        if (comment || (actions && actions.length)) {
          const jobFormResponsePayload = {
            predictionID,
            workflowTaskID,
            completeForToday: false,
            closeTasq: false,
            formResponse: actions,
            nodeID: well,
            validationResponse: { selectionActionComment: '', selectionAction: 'YES' },
          };

          await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
        }
      }
      return predictionID;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async addWorkTicket(data: any = {}) {
    const {
      assetType,
      well,
      jobType,
      frequency,
      delayedDays,
      stateChangeDate,
      stateChangeDateEnd,
      jobCategory,
      requestDetails,
      requestCompletionDate,
      files,
      checklist,
      priority,
      details,
    } = data;
    try {
      const {
        data: {
          post_new_tasq: { id: predictionID, workflow_task_id: workflowTaskID },
        },
      } : any = await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            level: assetType,
            assigned_to_user: accountModule.user.email,
            created_by: accountModule.user.email,
            node_id: well,
            prediction_type: jobType,
            ...(stateChangeDate && {
              state_change_date: new Date(stateChangeDate).toISOString().slice(0, -5),
            }),
            ...(stateChangeDateEnd && {
              state_change_date_end: new Date(stateChangeDateEnd).toISOString().slice(0, -5),
            }),
            ...(frequency === 'Reoccurance' && {
              recurring_start_date: new Date().toISOString(),
              recurring_end_date: new Date(new Date().getTime() + 86400000 * delayedDays).toISOString(),
            }),
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            tasq_type: 'Work Ticket',
            job_category: jobCategory,
            job_type: jobType,
            request_details: requestDetails,
            request_completion_date: requestCompletionDate,
            files: JSON.stringify(files),
            detailed_steps: JSON.stringify(checklist),
            priority,
            details: JSON.stringify(details),
          },
        },
      });
      return predictionID;
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async updateWorkTicket(data: any = {}) {
    const {
      assetType,
      well,
      jobType,
      assignedToUser,
      jobCateogry,
      requestDetails,
      requestCompletionDate,
      files,
      id,
      checklist,
      priority,
      status,
      details,
      ticketStartDate,
      ticketDueDate,
      statusStartDate,
      statusEndDate,
      reason,
    } = data;
    try {
      if (!well || !id) return;
      const {
        data: {
          update_work_ticket_tasq: { id: predictionID, workflow_task_id: workflowTaskID },
        },
      } : any = await predictionsApolloClient.mutate({
        mutation: updateWorkTicketTasq,
        variables: {
          input: {
            level: assetType,
            prediction_id: id,
            ...(assignedToUser && { assigned_to_user: assignedToUser }),
            node_id: well,
            tasq_type: 'Work Ticket',
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            job_category: jobCateogry,
            job_type: jobType,
            ...(requestDetails && { request_details: requestDetails }),
            ...(requestCompletionDate && { request_completion_date: requestCompletionDate }),
            ...(files && { files: JSON.stringify(files) }),
            ...(checklist && { detailed_steps: JSON.stringify(checklist) }),
            ...(priority && { priority }),
            ...(details && { details: JSON.stringify(details) }),
            tasq_status: {
              Status: status || 'Open',
              // # Open, In Progress, Blocked, Completed
              Reason: reason,
              StatusStartDate: statusStartDate,
              StatusEndDate: statusEndDate,
              TicketStartDate: ticketStartDate,
              TicketDueDate: ticketDueDate,
            },
          },
        },
      });
      return predictionID;
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async putDailyWorkTicketData(data: any = {}) {
    const {
      predictionID,
      nextTwentyFourHourSummary,
      twentyFourHourSummary,
      hoursSpentToday,
      dailyCostTable,
      dailyDate,
    } = data;
    try {
      if (!predictionID) return;
      await predictionsApolloClient.mutate({
        mutation: putDailyWorkTicketData,
        variables: {
          input: {
            PredictionID: predictionID,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            TwentyFourHourSummary: twentyFourHourSummary,
            NextTwentyFourHourSummary: nextTwentyFourHourSummary,
            DailyCostsTable: JSON.stringify(dailyCostTable),
            HoursSpentToday: hoursSpentToday,
            DailyDate: dailyDate,
          },
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async addActionTasq({ well, actionName, parentWorkflowID }) {
    try {
      const {
        data: {
          post_new_tasq: { id: predictionID, workflow_task_id: workflowTaskID },
        },
      } : any = await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            level: 'Well',
            assigned_to_user: accountModule.user.email.toLowerCase(),
            node_id: well,
            prediction_type: actionName,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            tasq_type: 'Action',
            parent_workflow_task_id: parentWorkflowID,
          },
        },
      });

      return predictionID;
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async deleteTasq({ id }) {
    try {
      await predictionsApolloClient.mutate({
        mutation: deleteTasq,
        variables: {
          input: {
            ID: id,
          },
        },
      });
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async postNewWellComment(comment) {
    try {
      await workflowApolloClient.mutate({
        mutation: postJobComment,
        variables: {
          input: {
            prediction_id: tasqsListModule.activeTasq?.id,
            username: accountModule.user.email.toLowerCase(),
            comment,
            should_post_as_global_well_comment: true,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  @Mutation
  async mutateTasqReassign({ comment, person, id }) {
    console.log(person)
    const email = typeof person === 'object' ? person.value.toLowerCase() : person.toLowerCase()
    await workflowApolloClient.mutate({
      mutation: reassignTasq,
      variables: {
        input: {
          id,
          username: email,
          comment,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
    let tasq = tasqsListModule.tasqById(id);
    if (tasq) {
      tasq.reassignedUserName = email;
      tasqsListModule.setIsLoadingTasqs(true);
      tasqsListModule.updateTasqDetails({ ...tasq });
      tasqsListModule.setIsLoadingTasqs(false);
    }

  }

  @Action
  async reassignTasq(tasq) {
    const { person, comment } = tasq;
    if ((tasqsListModule.isBatchReassign || tasqsListModule.isActionUnReleated) && tasqsListModule.checkedTasqs) {
      for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
        // eslint-disable-next-line no-param-reassign
        tasq.id = tasqsListModule.checkedTasqs[i];
        // eslint-disable-next-line no-await-in-loop
        await this.mutateTasqReassign(tasq);
      }
    } else {
      // console.log(tasqsListModule.activeTasq)
      // eslint-disable-next-line no-param-reassign
      tasq.id = tasqsListModule.activeTasq?.id;
      await this.mutateTasqReassign(tasq);
    }

    if (tasqsListModule.isActionUnReleated) {
      tasqsListModule.resetCheckedTasqs();
      tasqsListModule.setIsActionUnReleated(false);
    }
  }

  @Action
  async rejectTasq({ reason }) {
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          PredictionID: tasqsListModule.activeTasq?.id,
          Rejected: {
            Status: true,
            Reason: reason,
            // operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
          //   operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }

  @Mutation
  async mutateTasqUpdateStatus({ reason, comment = '', date, id, startDate }) {
    const newComment = `${reason} ${comment.length ? `-Remarks: ${comment}` : ''}`;
    let waitStartDate: any = new Date().toISOString().slice(0, -5);
    if (startDate) {
      waitStartDate = new Date(startDate).toISOString().slice(0, -5);
    }
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          PredictionID: id,
          //   Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Snoozed: {
            Status: true,
            UntilDate: new Date(date).toISOString().slice(0, -5),
            WaitUntilReason: newComment,
            WaitStartDate: waitStartDate,
            // Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      },
    });
  }

  @Mutation
  async mutateWellStatus({ id, wellName, comment, startDate, reason, endDate, level= 'WELL' }) {
    const newComment = `${reason} ${comment.length ? `-Remarks: ${comment}` : ''}`;
    let waitStartDate: any = new Date().toISOString().slice(0, -5);
    if (startDate) {
      waitStartDate = new Date(startDate).toISOString().slice(0, -5);
    }

    let snoozed = {
      Status: true,
      UntilDate: new Date(endDate).toISOString().slice(0, -5),
      WaitUntilReason: newComment,
      WaitStartDate: waitStartDate,
      // Operator: getConfigEnv('OPERATOR_LOWERCASED'),
    };
    await predictionsApolloClient.mutate({
      mutation: updateWellStatus,
      variables: {
        input: {
          NodeID: wellName,
          PredictionID: id,
          Username: accountModule.user.email.toLowerCase(),
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Snoozed: snoozed,
          Level: level,
        },
      },
    });

    let tasq = tasqsListModule.tasqById(id);
    if (tasq) {
      tasq.snoozed = snoozed;
      tasq.waitingOnStartDate = snoozed.WaitStartDate;
      tasq.waitingOnEndDate = snoozed.UntilDate;
      tasqsListModule.setIsLoadingTasqs(true);
      tasqsListModule.updateTasqDetails({ ...tasq });
      tasqsListModule.setIsLoadingTasqs(false);
    }
  }

  @Action
  async updateTasqStatus(action) {
    if ((tasqsListModule.isBatchReassign || tasqsListModule.isActionUnReleated) && tasqsListModule.checkedTasqs) {
      for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
        // eslint-disable-next-line no-param-reassign
        action.id = tasqsListModule.checkedTasqs[i];
        console.log(action);
        action.wellName = tasqsListModule.tasqById(action.id) ? tasqsListModule.tasqById(action.id)?.wellName : '';
        // eslint-disable-next-line no-await-in-loop
        const pramas = {
          id: action.id,
          wellName: action.wellName,
          comment: action.comment,
          startDate: action.startDate,
          reason: action.reason,
          endDate: action.date,
        };
        await this.mutateWellStatus(pramas);
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      action.id = tasqsListModule.activeTasq?.id;
      const pramas = {
        id: action.id,
        wellName: action.wellName,
        comment: action.comment,
        startDate: action.startDate,
        reason: action.reason,
        endDate: action.date,
      };
      await this.mutateWellStatus(pramas);
    }

    if (tasqsListModule.isActionUnReleated) {
      tasqsListModule.resetCheckedTasqs();
      tasqsListModule.setIsActionUnReleated(false);
    }
  }

  @Action
  async unsnoozeJob(id) {
    await predictionsApolloClient.mutate({
      mutation: unsnoozeJob,
      variables: {
        PredictionID: id,
      },
    });
  }
}

export default getModule(TasqActionsModule);
